<template>
  <div class="page">
    <div class="nav-bar">
      <div class="left" @click="goBack">
        <van-icon name="arrow-left" size="24" color="#1D1D2C" />
      </div>
      <div class="center">会员详情</div>
      <div class="right"></div>
    </div>
    <div class="nav-bar-block" style="height: 96px"></div>
    <template v-for="(item, index) in pageData" :key="index">
      <div class="member-card" v-if="item.equityType==0">
        <div class="row-1">{{item.equityTitle}}</div>
        <div class="row-2">有效日期：{{item.equityStartTime}}至{{item.equityExpireTime}}</div>
      </div>
      <div class="extra-card" v-if="item.equityType==1">
        <div class="row-1">{{item.equityTitle}}</div>

        <div class="row-2">
          <div class="block" :style="{width:(item.equityUseNum/ item.equityNum)*100 + '%'}"></div>
        </div>
        <div class="row-3">已使用次数/可使用次数：{{item.equityUseNum}}/ {{item.equityNum}}</div>
        <div class="row-4">有效日期：{{item.equityStartTime}}至{{item.equityExpireTime}}</div>
      </div>
    </template>
  </div>
</template>

<script >
import { getMemberDetail } from './api.js';
export default {
  data() {
    return {
      pageData: {},
    };
  },
  mounted() {
    this.getMemberDetail();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getMemberDetail() {
      getMemberDetail().then((res) => {
        this.pageData = res.data;
      });
    },
  },
};
</script>

<style  lang="less" scoped>
.page {
  background: #f5f7f9;
  height: 100vh;
  overflow: scroll;
}
.nav-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  height: 84px;
  padding-top: 50px;
  display: flex;
  justify-content: space-between;
  background: #fff;
}
.member-card {
  height: 116px;
  margin: 8px 16px;
  background: url(../../../assets/card-bg.png);
  background-size: 100% 100%;
  padding: 28px 16px;
  box-sizing: border-box;
  .row-1 {
    font-weight: 700;
    font-size: 24px;
    color: #ffe0c0;
  }
  .row-2 {
    font-weight: 400;
    font-size: 12px;
    color: #a68c7e;
    margin-top: 12px;
  }
}
.extra-card {
  margin: 0 16px;
  background: url(../../../assets/card-bg.png);
  background-size: 100% 100%;
  height: 170px;
  padding: 32px 16px;
  box-sizing: border-box;
  margin-bottom: 8px;
  .row-1 {
    font-weight: 700;
    font-size: 24px;
    color: #ffe0c0;
  }
  .row-2 {
    margin-top: 16px;
    height: 16px;
    background: #505474;
    border-radius: 2px;
    overflow: hidden;
    .block {
      height: 16px;
      background: #757bb2;
      width: 70%;
    }
  }
  .row-3 {
    margin-top: 4px;
    font-size: 14px;
    color: #cdad9c;
  }
  .row-4 {
    margin-top: 8px;
    font-size: 12px;
    color: #a68c7e;
  }
}
</style>